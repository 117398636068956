"use client";

import { useTracking } from "@/hooks/tracking/use-tracking";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { TrackPageView } from "./TrackPageView.component";
type TrackPageViewContainerProps = {
  readonly referrer: null | string;
};
export function TrackPageViewContainer({
  referrer
}: TrackPageViewContainerProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [absoluteUrl, setAbsoluteUrl] = useState<null | string>(null);
  const {
    setupPageViewTracking
  } = useTracking();
  setupPageViewTracking();
  useEffect(() => {
    const origin = window.location.origin;
    const query = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
    setAbsoluteUrl(`${origin}${pathname}${query}`);
  }, [pathname, searchParams]);
  return absoluteUrl !== null ? <TrackPageView currentURL={encodeURIComponent(absoluteUrl)} key="archive" referrer={referrer} data-sentry-element="TrackPageView" data-sentry-component="TrackPageViewContainer" data-sentry-source-file="TrackPageViewContainer.component.tsx" /> : null;
}