"use client";

import "client-only";
import { useClickTracking } from "./click/use-click-tracking";
import { useConversionTracking } from "./conversion/use-conversion-tracking.hook";
import type { UseTrackingEmitter } from "./emitter/use-tracking-emitter.hook";
import { useTrackingEmitter } from "./emitter/use-tracking-emitter.hook";
import { usePageViewTracking } from "./page-view/use-page-view-tracking.hook";

type UseTracking = {
  /**
   * Emit a custom event to the tracking system.
   *
   * Example:
   *
   * ```tsx
   * <Link onClick={() => emit("click", payload)}>Click me</Link>
   * ```
   */
  readonly emit: UseTrackingEmitter["emit"];

  /**
   * Initialize page view tracking.
   *
   * Example:
   *
   * ```tsx
   * setupPageViewTracking();
   * ```
   */
  readonly setupPageViewTracking: typeof usePageViewTracking;

  /**
   * Subscribe to tracking events.
   * Please remember to wrap the callback in useCallback to control when the subscription is updated.
   *
   * Example:
   *
   * ```tsx
   * const callback = useCallback((model: PageViewTrackingModel) => {
   *  sendClickEventToAnalyticsProvider(model);
   * }, []);
   *
   * subscribe("click", callback);
   * ```
   */
  readonly subscribe: UseTrackingEmitter["subscribe"];

  /**
   * Shorthand utility function for tracking a click event.
   *
   * Example:
   *
   * ```tsx
   * <Link onClick={() => trackClick(payload)}>Click me</Link>
   */
  readonly trackClick: typeof useClickTracking;

  /**
   * Shorthand utility function for tracking a conversion event.
   *
   * Example:
   *
   * ```tsx
   * trackConversion(conversionTags);
   * ```
   */
  readonly trackConversion: typeof useConversionTracking;
};

export function useTracking(): UseTracking {
  const { emit, subscribe } = useTrackingEmitter();

  return {
    emit,
    setupPageViewTracking: usePageViewTracking,
    subscribe,
    trackClick: useClickTracking,
    trackConversion: useConversionTracking,
  };
}
