"use client";

import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { jpmuidDefault } from "@/hooks/tracking/jpmuid/jpmuid";
import { isbot } from "isbot";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const uuidLength = 36;

export function useJpmuid(): {
  readonly jpmuid: string;
} {
  const { trackingData } = useTrackingData();
  const [jpmuid, setJpmuid] = useState<string>(
    trackingData?.jpmuid ?? jpmuidDefault.anon,
  );
  const { statistics, submitted } = useCookiebotConsent();
  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    if (isbot(navigator.userAgent)) {
      setJpmuid(jpmuidDefault.bot);
      return;
    }
    if (!submitted || (statistics && jpmuid !== jpmuidDefault.anon)) return;
    const jpmuidFromCookie = readJpmuidCookie();
    const newJpmuid =
      jpmuidFromCookie !== jpmuidDefault.anon
        ? jpmuidFromCookie
        : determineNewJpmuid(statistics);
    setJpmuid(newJpmuid);
    manageJpmuidCookie(newJpmuid, statistics);
  }, [submitted, statistics]);

  return { jpmuid };
}

function readJpmuidCookie(): string {
  const jpmuidFromCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("jpmuid="))
    ?.split("=")[1];
  const validJpmuid =
    typeof jpmuidFromCookie !== "undefined" &&
    jpmuidFromCookie.length === uuidLength;
  return validJpmuid ? jpmuidFromCookie : jpmuidDefault.anon;
}

function manageJpmuidCookie(jpmuid: string, statistics: boolean) {
  // biome-ignore lint/nursery/noDocumentCookie: This is necessary for cookie management
  document.cookie = statistics
    ? `jpmuid=${jpmuid}; Max-Age=31536000; ${cookieExpirationString(
        1,
      )}; SameSite=None; Path=/; Secure;`
    : `jpmuid=; Expires=${cookieExpirationString(-1)}; Path=/;`;
}

function determineNewJpmuid(statistics: boolean) {
  return statistics ? uuidv4() : jpmuidDefault.anon;
}

function cookieExpirationString(years: number) {
  const date = new Date();
  date.setFullYear(date.getFullYear() + years);
  return `Expires=${date.toUTCString()}`;
}
