"use client";

import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useTrackingVersion } from "@/hooks/tracking/use-tracking-version";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { useCookiebotConsent } from "../../cookiebot/use-cookiebot-consent.hook";
import { useTrackingEmitter } from "../emitter/use-tracking-emitter.hook";

export function usePageViewTracking() {
  const { emit } = useTrackingEmitter();
  const pathname = usePathname();
  const searchParams = useSearchParams().toString();
  const [isTracked, setIsTracked] = useState(true);
  const consent = useCookiebotConsent();
  const { trackingData } = useTrackingData();
  const trackingVersion = useTrackingVersion();

  useEffect(() => {
    if (
      trackingData === null ||
      isTracked ||
      !consent.submitted ||
      trackingVersion !== "v2"
    ) {
      return;
    }

    const {
      contentAuthor,
      contentModifiedTime,
      contentPublishTime,
      conversion,
      description,
      domain,
      imageUrlPath,
      pageRestrictedType,
      pageType,
      referrer,
      sectionName,
      title,
    } = trackingData;

    emit("pageview", {
      contentAuthor,
      contentModifiedTime,
      contentPublishTime,
      conversion,
      description,
      domain,
      imageUrlPath,
      pageRestrictedType,
      pageType,
      referrer,
      sectionName,
      title,
      url: `https://${domain}${pathname}${searchParams ? searchParams.toString() : ""}`,
    });

    setIsTracked(true);
  }, [
    isTracked,
    emit,
    consent,
    trackingData,
    pathname,
    searchParams,
    trackingVersion,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than it needs
  useEffect(() => setIsTracked(false), [searchParams, pathname]);
}
