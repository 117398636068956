"use client";

import { InitializeLinkedInInsightTag } from "@/components/Tracking/LinkedIn/InitializeLinkedInInsightTag";
import { TrackPageViewContainer } from "@/components/Tracking/TrackPageViewContainer.component";
import { InitializeExponea } from "@/components/Tracking/exponea/InitializeExponea";
import type { TrackingDataModel } from "@/hooks/tracking/models/tracking-data-model.types";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";
const TrackingContext = createContext<TrackingDataModel | undefined>(undefined);
type TrackingProviderProps = {
  readonly model: TrackingDataModel;
  readonly children: ReactNode;
};
type UseTrackingContextReturn = {
  readonly trackingData: null | TrackingDataModel;
};
export function useTrackingData(): UseTrackingContextReturn {
  const trackingData = useContext(TrackingContext) ?? null;
  return {
    trackingData
  };
}
export function TrackingProvider({
  children,
  model
}: TrackingProviderProps) {
  return <TrackingContext.Provider value={model} data-sentry-element="unknown" data-sentry-component="TrackingProvider" data-sentry-source-file="tracking.context.tsx">
      <InitializeExponea trackingData={model} data-sentry-element="InitializeExponea" data-sentry-source-file="tracking.context.tsx" />
      {model.linkedinInsightId !== null ? <InitializeLinkedInInsightTag trackingData={model} /> : null}
      <TrackPageViewContainer referrer={model.referrer} data-sentry-element="TrackPageViewContainer" data-sentry-source-file="tracking.context.tsx" />
      {children}
    </TrackingContext.Provider>;
}