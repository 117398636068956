import type { ConversionEvent } from "@/components/Tracking/Kilkaya/kilkaya.types";
import { useEffect } from "react";
import { useTrackingEmitter } from "../emitter/use-tracking-emitter.hook";

export function useConversionTracking(conversionTags: ConversionEvent | null) {
  const { emit: emitTrackingEvent } = useTrackingEmitter();

  useEffect(() => {
    if (conversionTags !== null) {
      emitTrackingEvent("conversion", { event: conversionTags });
    }
  }, [conversionTags, emitTrackingEvent]);
}
