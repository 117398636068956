"use client";

import { fetchFeatureFlag } from "@/app/(sites)/_api/feature-flags/fetch-feature-flag";
import { captureClientException } from "@/services/logging/capture-client-exception";
import { useEffect, useState } from "react";

type UseTrackingVersionStates = "v1" | "v2" | "pending" | "error";

export function useTrackingVersion(): UseTrackingVersionStates {
  const [featureFlag, setFeatureFlag] =
    useState<UseTrackingVersionStates>("pending");

  useEffect(() => {
    const fetchFeatureFlagResponse = async () => {
      const response = await fetchFeatureFlag("use-tracking-v2");
      const isUsingV2 = Boolean(response);
      setFeatureFlag(isUsingV2 ? "v2" : "v1");
    };

    fetchFeatureFlagResponse().catch((error) => {
      setFeatureFlag("error");
      captureClientException(error);
    });
  }, []);

  return featureFlag;
}
