"use client";

import { EventEmitter as Emitter } from "eventemitter3";
import { useEffect } from "react";
import type {
  TrackingEmitterFunctions,
  TrackingEvents,
} from "./tracking-emitter.types";

const emitter = new Emitter();

export type UseTrackingEmitter = {
  readonly emit: <Event extends TrackingEvents>(
    trackingEvent: Event,
    model: Parameters<TrackingEmitterFunctions[Event]>[0],
  ) => void;
  readonly subscribe: <Event extends TrackingEvents>(
    event: Event,
    callback: TrackingEmitterFunctions[Event],
  ) => () => void;
};

const subscribe = <Event extends TrackingEvents>(
  event: Event,
  callback: TrackingEmitterFunctions[Event],
) => {
  const unsubscribe = () => {
    emitter.off(event, callback);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook does not specify all of its dependencies
  useEffect(() => {
    emitter.on(event, callback);
    return unsubscribe;
  }, [callback]);

  return unsubscribe;
};

const emit = <Event extends TrackingEvents>(
  trackingEvent: Event,
  model: Parameters<TrackingEmitterFunctions[Event]>[0],
) => {
  emitter.emit(trackingEvent, model);
};

export const useTrackingEmitter: () => UseTrackingEmitter = () => ({
  /**
   * Emit a tracking event
   */
  emit,

  /**
   * Subscribe to a tracking event
   */
  subscribe,
});
